<template>
  <!-- 货物品类类型 -->
  <div>
    <div class="box">
      <!-- 左边 -->
      <div class="boxleft glo_Table">
        <!-- 按钮列表 -->
        <div class="glo_btnList" ref="btnList2">
          <el-input
            style="margin-bottom: 10px"
            clearable
            placeholder="请输入分组名查询"
            @keyup.enter.native="queryBtn_Left"
            v-model="groupNameKey"
          ></el-input>
          <queryBtns
            style="margin-bottom: 15px"
            :MoreConditions="MoreConditions"
            :showMoreBtns="false"
            :showcancelBtns="false"
            @clerBtns="clerBtns"
            @queryBtn_ok="queryBtn_Left"
            @moreParam="moreParam"
          ></queryBtns>
          <div class="glo_btnList_left">
            <el-button @click="AddGroup()" class="glo_btnItems glo_bgcMainColor"
              >新建分组</el-button
            >
          </div>
        </div>
        <el-table
          :data="menuTableData"
          style="width: 100%"
          row-key="typeId"
          border
          :height="leftHeight"
          default-expand-all
          @row-click="rowClickLeft"
          :tree-props="{
            children: 'childCategoryTypeList',
            hasChildren: 'hasChildren',
          }"
        >
          <el-table-column prop="groupName" label="分组名" width="140">
          </el-table-column>
          <el-table-column prop="sort" sortable label="排序" width="65">
          </el-table-column>
          <el-table-column prop="goodsCount" label="商品数" width="65">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small">
                <span class="tablebut" @click="menumodify(scope.row)"
                  >修改</span
                >
                <span class="tablebut" @click="delBtns(scope.row.groupId)"
                  >删除</span
                >
                <!-- <span
                  v-if="scope.row.isShow == 0"
                  class="tablebut"
                  @click="qiYong(scope.row.typeId)"
                  >启用</span
                >
                <span
                  v-if="scope.row.isShow == 1"
                  class="tablebut"
                  @click="jinYong(scope.row.typeId)"
                  >禁用</span
                > -->
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 右边 -->
      <div class="boxright">
        <!-- 按钮列表 -->
        <div class="glo_btnList" ref="btnList">
          <div class="glo_btnList_left">
            <el-button @click="addServe()" class="glo_btnItems glo_bgcMainColor"
              >添加商品</el-button
            >
            <el-button class="glo_btnItems" @click="qiYongMore()"
              >上架
            </el-button>
            <el-button class="glo_btnItems" @click="jinYongMore()"
              >下架
            </el-button>
            <el-button @click="DelServe_R()" class="glo_btnItems"
              >删除商品</el-button
            >

            <!-- <el-button @click="delMore" class="glo_btnItems">导出</el-button> -->
          </div>

          <queryBtns
            :MoreConditions="MoreConditions"
            :showMoreBtns="false"
            @clerBtns="clerBtns"
            @queryBtn_ok="queryBtn_ok"
            @moreParam="moreParam"
          ></queryBtns>
        </div>
        <!-- 查询条件 -->
        <div ref="queryForm">
          <el-form
            :model="queryData"
            class="glo_form hasMoreParam"
            label-position="left"
          >
            <el-form-item class="queryItems" label="分组:">
              <el-select
                v-model="queryData.groupId"
                clearable
                placeholder="请输入关键词"
                filterable
                remote
                :loading="false"
                @change="changeTypeName1"
              >
                <el-option
                  v-for="item in menuTableData"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="queryItems" label="商品标题:">
              <el-input
                clearable
                placeholder="请输入"
                @keyup.enter.native="queryBtn_ok"
                v-model="queryData.goodsName"
              ></el-input>
            </el-form-item>
            <el-form-item class="queryItems" label="第三方平台:">
              <el-select
                v-model="queryData.platformType"
                clearable
                placeholder="请输入关键词"
                filterable
              >
                <el-option
                  v-for="(item, index) in platform_type_list"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="queryItems" label="商品状态:">
              <el-select
                v-model="queryData.goodsStatus"
                clearable
                placeholder="请输入关键词"
                filterable
              >
                <el-option
                  v-for="(item, index) in goods_status_list"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <!-- 表格 -->
        <div class="glo_Table" ref="tableWrapper">
          <el-table
            :height="tableHeigth"
            ref="mytable"
            @row-click="rowclick"
            :data="tableData"
            stripe
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column fixed="left" label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column fixed="left" type="selection" width="60">
            </el-table-column>
            <template v-for="(item, index) in myTableHeard">
              <el-table-column
                :key="index"
                :label="item.name"
                :prop="item.field"
                sortable
                :min-width="item.width"
                :data-isTotal="item.isTotal"
                v-if="item && item.field == 'goodsStatusShow'"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div
                    style="color: #ef7c0a"
                    v-if="scope.row.goodsStatusShow == '上架'"
                  >
                    {{ scope.row.goodsStatusShow }}
                  </div>
                  <div v-else>{{ scope.row.goodsStatusShow }}</div>
                </template>
              </el-table-column>
              <el-table-column
                :key="index"
                :label="item.name"
                :prop="item.field"
                sortable
                :min-width="item.width"
                :data-isTotal="item.isTotal"
                v-else-if="item && item.isShow"
                show-overflow-tooltip
              >
              </el-table-column>
            </template>
            <el-table-column fixed="right" label="操作" min-width="200px">
              <template slot-scope="scope">
                <div class="tabRightBtns">
                  <el-button class="rbtns" @click.stop="editorServe(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="qiYongMore(scope.row.goodsId)"
                    >上架</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="jinYongMore(scope.row.goodsId)"
                    >下架</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="DelServe_R(scope.row.goodsId)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column fixed="right" label="操作" min-width="220px">
              <template slot-scope="scope">
                <div class="tabRightBtns">
                  <el-button class="rbtns" @click.stop="menumodify(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="qiYong(scope.row.typeId)"
                    >启用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="jinYong(scope.row.typeId)"
                    >禁用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="delBtns(scope.row.typeId)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <!-- 分页组件 -->
        <paging
          ref="pags"
          :pageNum="pageStart"
          :total="total"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
      </div>
    </div>

    <!--        自定义表头           -->
    <!-- <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    >
    </sorttable> -->

    <!-- 弹窗 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="PopUpTitle"
      :isShowDialog="menushowBOX"
      @handleCloseDia="handleCloseDia"
      @diaCancelBtn="handleCloseDia"
      @diaConfirmBtn="diaConfirmBtn('dialogFormDataRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="menushowBOX"
          ref="dialogFormDataRef"
          :model="dialogFormData"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item
            class="dialogFormItems"
            prop="typeName"
            label="类型名称:"
          >
            <el-input
              placeholder="请输入"
              clearable
              v-model="dialogFormData.typeName"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="parentId"
            label="上级类型名称:"
          >
            <el-select
              v-model="dialogFormData.parentId"
              clearable
              placeholder="请输入关键词"
              filterable
              remote
              :loading="false"
            >
              <el-option
                v-for="item in parentList"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input
              placeholder="请输入"
              v-model="dialogFormData.sort"
            ></el-input>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="isShow" label="启用状态:">
            <el-select
              clearable
              filterable
              v-model="dialogFormData.isShow"
              placeholder="请选择"
            >
              <el-option label="启用" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dislogFormTextarea" prop="remark" label="备注:">
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogFormData.remark"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            prop="conflictTypeId"
            label="冲突类型:"
          >
            <el-checkbox-group v-model="dialogFormData.conflictTypeId">
              <el-checkbox
                v-for="item in tableDataALL"
                :key="item.typeId"
                :label="item.typeId"
                >{{ item.typeName }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>

    <!-- 新建/编辑商品 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="dialogTitle"
      :isShowDialog="isShowDialog"
      @handleCloseDia="handleCloseDiaR"
      @diaCancelBtn="handleCloseDiaR"
      @diaConfirmBtn="diaConfirmBtnR('dialogFormRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="isShowDialog"
          ref="dialogFormRef"
          :model="dialogForm"
          label-width="100px"
          :rules="rulesForm"
        >
          <el-form-item
            class="dialogFormItems"
            prop="goodsGroupId"
            label="所属分组:"
          >
            <el-select
              v-model="dialogForm.goodsGroupId"
              clearable
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in menuTableData"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="goodsIdNumber"
            label="商品ID:"
          >
            <el-input v-model.trim="dialogForm.goodsIdNumber"></el-input>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            prop="goodsUrl"
            label="商品链接:"
          >
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogForm.goodsUrl"
              @input="changeUrl"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            prop="goodsName"
            label="商品标题:"
          >
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogForm.goodsName"
            ></el-input>
          </el-form-item>

          <el-form-item
            class="dialogFormItems"
            prop="goodsStatus"
            label="商品状态:"
          >
            <el-select
              filterable
              clearable
              v-model="dialogForm.goodsStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in goods_status_list"
                :key="index"
                :label="item.showName"
                :value="item.statusVal"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="dialogFormItems"
            prop="platformType"
            label="第三方平台:"
          >
            <el-select
              filterable
              clearable
              v-model="dialogForm.platformType"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in platform_type_list"
                :key="index"
                :label="item.showName"
                :value="item.statusVal"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="dialogFormItems"
            prop="priceStart"
            label="最低价:"
          >
            <el-input v-model="dialogForm.priceStart"></el-input>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="priceEnd" label="最高价:">
            <el-input v-model="dialogForm.priceEnd"></el-input>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input v-model="dialogForm.sort"></el-input>
          </el-form-item>
          <el-form-item style="width: 100%" label="商品主图:">
            <uploadimgs
              @input="changeFileList"
              :fileNameLists="fileNameLists"
              :limit="1"
            ></uploadimgs>
            <div>请选择一张图</div>
          </el-form-item>
          <el-form-item style="width: 100%" label="商品图片">
            <uploadimgs
              @input="changeFileList2"
              :fileNameLists="fileNameLists2"
              :limit="6"
            ></uploadimgs>
            <div>(最多上传6张)</div>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>

    <!-- 新建分组 -->
    <myDialog
      diaWidth="38%"
      :dialogTitle="dialogTitle_fz"
      :isShowDialog="isShowDialog_fz"
      @handleCloseDia="handleCloseDiaR_fz"
      @diaCancelBtn="handleCloseDiaR_fz"
      @diaConfirmBtn="diaConfirmBtnR_fz('dialogFormRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="isShowDialog_fz"
          ref="dialogFormRef"
          :model="dialogForm_fz"
          label-width="100px"
          :rules="rulesForm_fz"
        >
          <el-form-item class="dialogFormItems" prop="groupName" label="组名:">
            <el-input
              clearable
              placeholder="请输入"
              v-model="dialogForm_fz.groupName"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            class="dialogFormItems"
            prop="isEnable"
            label="启用状态:"
          >
            <el-select
              filterable
              clearable
              v-model="dialogForm_fz.isEnable"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in isShowList"
                :key="index"
                :label="item.name"
                :value="item.val"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input
              clearable
              type="number"
              placeholder="请输入"
              v-model="dialogForm_fz.sort"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>


<script>
import paging from "@/components/pagings.vue";
import queryBtns from "@/components/queryBtns.vue";
import myDialog from "@/components/Dialog.vue";
// import sorttable from "@/components/sortTemplate.vue"; //自定义排序表头
import uploadimgs from "@/components/upLoadImgs.vue";
export default {
  inject: ["reload"],
  components: {
    paging,
    queryBtns,
    myDialog,
    // sorttable,
    uploadimgs,
  },
  data() {
    return {
      dialogFormData: {
        conflictTypeId: [],
        isShow: "1",
        parentId: "",
        remark: "",
        sort: "1",
        typeName: "",
      },
      rules: {
        typeName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        isShow: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
      PopUpTitle: "", //弹窗标题
      menushowBOX: false, //菜单弹窗
      menuTableData: [],
      diaWidth: "40%",
      //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

      queryData: {
        goodsName: "",
        goodsStatus: "",
        groupId: "",
        platformType: "",
      },

      // ================
      pageStart: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      pageTotal: this.common.pageTotals, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      leftHeight: "75vh",
      tableHeigth: "75vh",
      tabelHeadeTitle: [
        {
          name: "所属分组",
          field: "groupName",
          width: "100",
          sort: 3,
          isShow: true, //是否展示
          isTotal: true, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "商品编号",
          field: "number",
          width: "100",
          sort: 3,
          isShow: true, //是否展示
          isTotal: true, //是否开启合计
          isSort: true, //表格设置里面是否可以排序
        },
        {
          name: "商品标题",
          field: "goodsName",
          width: "200",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "来源",
          field: "platformTypeShow",
          width: "80",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "最低价",
          field: "priceStart",
          width: "100",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "最高价",
          field: "priceEnd",
          width: "100",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商品ID",
          field: "goodsIdNumber",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "状态",
          field: "goodsStatusShow",
          width: "80",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "排序",
          field: "sort",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建人",
          field: "createUserName",
          width: "110",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      tableDataALL: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      sortName: "货物品类类型模板", //排序名
      pageGroupName: "GoodsCategoryType", //页面标识
      selectTableData: [], //选中的数据

      deperIds: [],
      MoreConditions: "更多条件", //  输入框绑定动态class
      isHaseMore: false, //false == 更多条件  true ==收起条件
      dialogType: "add", //add==新建   edit==修改

      parentList: [], //上级
      conflictTypeIds: [], //原来的冲突品类类型

      // ==== 弹窗组件属性 start ====
      isShowDialog: false,
      dialogTitle: "弹窗标题",
      diaWidth: "40%",
      dialogForm: {
        // 弹窗表单数据
        goodsGroupId: "",
        goodsIdNumber: "",
        goodsName: "",
        goodsStatus: "shang_jia:goods_status",
        goodsUrl: "",
        mainPicId: "",
        picIdReqs: [],
        platformType: "taobao:platform_type",
        priceEnd: "",
        priceStart: "",
        sort: 1,
      },
      rulesForm: {
        goodsGroupId: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        goodsIdNumber: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        goodsName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        goodsStatus: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        platformType: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        sort: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },

      isShowList: [
        {
          name: "启用",
          val: 1,
        },
        {
          name: "禁用",
          val: 0,
        },
      ],
      goodsStatusList: [
        {
          name: "上架",
          val: "shang_jia:goods_status",
        },
        {
          name: "下架",
          val: "xia_jia:goods_status",
        },
      ],
      dialogTypeR: "add",
      // 分组弹窗
      dialogTitle_fz: "分组名称",
      isShowDialog_fz: false,
      dialogTypeR_fz: "",
      rulesForm_fz: {
        groupName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        sort: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
      dialogForm_fz: {
        groupName: "",
        sort: "",
        isEnable: 1,
        parentId: "",
      },
      groupNameKey: "",

      fileNameLists: [], //商品主图
      fileNameLists2: [], //商品图片

      platform_type_list: [], //第三方平台类
      goods_status_list: [], //商品状态
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  watch: {
    // "dialogForm.goodsUrl": {
    //   handler(a, b) {
    //     console.log(a, "a");
    //   },
    //   immediate: true,
    // },
    "dialogForm.goodsUrl": (newV, oldV) => {
      console.log(newV, "newV");
    },
  },
  created() {
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    // this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endtime = this.Tools.getCurrentDate(); //今天的时间;
    this.sarttime = this.Tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];

    // 左边列表
    this.leftTableData();
  },
  mounted() {
    const that = this;
    this.getStatusValList("goods_v2.goods_status");
    this.getStatusValList("goods_v2.platform_type");
    // 右边列表
    // this.getData();
  },
  activated() {
    this.$nextTick(() => {
      this.fetTableHeight();
    });
  },
  watch: {},
  methods: {
    changeUrl(e) {
      let val = e;
      let arrs = [];
      if (val.indexOf("?") !== -1) {
        this.dialogForm.goodsIdNumber = "";

        arrs = val.split("?");
        // 提取1688的商品id
        if (arrs[0].indexOf("/offer/") !== -1) {
          let arr1688 = arrs[0].split("/offer/");
          let arr1688id = arr1688[1].split(".")[0] || "";
          this.dialogForm.goodsIdNumber = arr1688id;
          console.log("arr1688", arr1688);
        }

        // 淘宝/天猫提取id
        arrs = arrs[1].split("&");

        let Lists = [];
        arrs.forEach((item) => {
          let arr2 = item.split("=");
          let obj = {
            key: arr2[0],
            val: arr2[1],
          };
          Lists.push(obj);
        });
        Lists.forEach((item2) => {
          if (item2.key == "id") {
            this.dialogForm.goodsIdNumber = item2.val;
          }
        });
      }
    },
    changeFileList(array) {
      this.fileNameLists = array;
    },
    changeFileList2(array) {
      this.fileNameLists2 = array;
    },

    getStatusValList(type) {
      this.$comAPI.getStatusValList(type).then((res) => {
        // 第三方平台类型; this.getStatusValList("goods_v2.platform_type")
        if (type == "goods_v2.platform_type") {
          this.platform_type_list = res || [];
        }

        // 商品状态  this.getStatusValList("goods_v2.goods_status")
        if (type == "goods_v2.goods_status") {
          this.goods_status_list = res || [];
        }
      });
    },

    // 新建分组
    AddGroup() {
      this.dialogTypeR_fz = "add";
      this.isShowDialog_fz = true;
      this.dialogTitle_fz = "新建分组";
    },
    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDiaR() {
      this.isShowDialog = false;
      this.dialogForm = {
        goodsGroupId: "",
        goodsIdNumber: "",
        goodsName: "",
        goodsStatus: "shang_jia:goods_status",
        goodsUrl: "",
        mainPicId: "",
        picIdReqs: [],
        platformType: "taobao:platform_type",
        priceEnd: "",
        priceStart: "",
        sort: 1,
      };
      this.fileNameLists = [];
      this.fileNameLists2 = [];
    },
    //  弹窗确认保存事件
    diaConfirmBtnR(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let picIdReqs = [];
          let mainPicId = "";
          console.log(this.fileNameLists, "fileNameLists");
          if (this.fileNameLists.length == 0) {
            this.$message.error("主图不能为空");
            return false;
          }
          mainPicId = this.fileNameLists[0].name;
          picIdReqs = this.fileNameLists2.map((item) => {
            return item.name;
          });
          let param = {
            goodsGroupId: this.dialogForm.goodsGroupId,
            goodsIdNumber: this.dialogForm.goodsIdNumber,
            goodsName: this.dialogForm.goodsName,
            goodsStatus:
              this.dialogForm.goodsStatus || "shang_jia:goods_status",
            goodsUrl: this.dialogForm.goodsUrl,
            mainPicId: mainPicId,
            picIdReqs: picIdReqs || [],
            platformType: this.dialogForm.platformType,
            priceEnd: this.dialogForm.priceEnd || 0,
            priceStart: this.dialogForm.priceStart || 0,
            sort: this.dialogForm.sort || 1,
          };
          if (this.dialogTypeR == "add") {
            // 新建
            param.sign = this.Tools.getSign(param);
            this.$API.addGoods(param).then((res) => {
              this.$message.success(res.data.message || "新建成功");
              this.getData();
              this.isShowDialog = false;
            });
          } else {
            // 编辑
            param.goodsId = this.dialogForm.goodsId || "";
            param.sign = this.Tools.getSign(param);
            this.$API.updateGoods(param).then((res) => {
              this.$message.success(res.data.message || "修改成功");
              this.getData();
              this.isShowDialog = false;
            });
          }
        }
      });
    },
    //  关闭弹窗按钮事件(关闭按钮)
    diaCancelBtn() {
      this.isShowDialog = false;
    },

    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDiaR_fz() {
      this.isShowDialog_fz = false;
      this.dialogForm_fz = {
        groupName: "",
        sort: "",
        isEnable: 1,
        parentId: "",
      };
    },
    //  弹窗确认保存事件
    diaConfirmBtnR_fz(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isShowDialog_fz = false;
          let param = {
            groupName: this.dialogForm_fz.groupName || "",
            sort: this.dialogForm_fz.sort || 0,
            // isEnable: "",
            // isEnable: this.dialogForm_fz.isEnable || 0,
            // parentId: this.dialogForm_fz.parentId || "",
          };
          if (this.dialogTypeR_fz == "add") {
            // 新建
            param.sign = this.Tools.getSign(param);
            this.$API.addGoodsGroup(param).then((res) => {
              this.$message.success(res.data.message || "新建成功");
              this.leftTableData();
            });
          } else {
            // 编辑
            param.groupId = this.dialogForm_fz.groupId || "";
            delete param.parentId;
            delete param.isEnable;
            param.sign = this.Tools.getSign(param);
            this.$API.updateGoodsGroup(param).then((res) => {
              this.$message.success(res.data.message || "修改成功");
              this.leftTableData();
            });
          }
        }
      });
    },

    // 新建
    addServe() {
      this.isShowDialog = true;
      this.dialogTitle = "添加商品";
      this.dialogTypeR = "add";
    },

    /***
     * ====== 弹窗组件方法 ======
     */
    // 编辑
    editorServe(row) {
      console.log(row);
      //   this.dialogForm = row;

      this.$API
        .getGoodsDetail({
          goodsId: row.goodsId,
        })
        .then((res) => {
          this.isShowDialog = true;
          this.dialogTitle = "编辑商品";
          this.dialogTypeR = "edit";
          this.dialogForm = res.data.result;

          // 主图
          let obj1 = {
            url: this.dialogForm.mainPicUrl,
            name: this.dialogForm.mainPicId,
          };
          this.fileNameLists = [obj1];

          // 图片
          let picVos = res.data.result.picVos || [];
          picVos.forEach((item) => {
            let obj = {
              url: item.fileUrl,
              name: item.fileId,
            };
            this.fileNameLists2.push(obj);
          });
        });
    },

    // 删除
    DelServe_R(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.goodsId;
        });
      }

      this.$confirm("是否删除所选数据!", "提示:")
        .then(() => {
          let param = {
            goodsIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delGoods(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },

    // =======================================

    // 查询条件
    changeTypeName1(e) {
      this.queryBtn_ok();
    },

    // 批量启用
    qiYongMore(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.goodsId;
        });
      }

      this.$confirm("是否上架所选商品?", "提示:")
        .then((respon) => {
          let param = {
            goodsIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.upShelfGoods(param).then((res) => {
            this.$message.success(res.data.message || "上架成功");
            // this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 批量下架
    jinYongMore(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要操作的数据");
          return false;
        }
        ids = this.selectTableData.map((item) => {
          return item.goodsId;
        });
      }
      this.$confirm("是否下架所选商品?", "提示:")
        .then((respon) => {
          let param = {
            goodsIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.downShelfGoods(param).then((res) => {
            this.$message.success(res.data.message || "禁用成功");
            // this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 启用
    qiYong(id) {
      this.$confirm("是否启用所选数据?", "提示:")
        .then((respon) => {
          let ids = [id];
          this.$API.wtl_startCategoryType({ typeIds: ids }).then((res) => {
            this.$message.success(res.data.message || "启用成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },
    // 禁用
    jinYong(id) {
      this.$confirm("是否禁用所选数据?", "提示:")
        .then((respon) => {
          let ids = [id];
          this.$API.wtl_stopCategoryType({ typeIds: ids }).then((res) => {
            this.$message.success(res.data.message || "禁用成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },

    // 点击选中当前行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    clerBtns() {
      //  重置按钮
      this.queryData = {
        goodsName: "",
        goodsStatus: "",
        groupId: "",
        platformType: "",
      };
    },

    moreParam() {
      this.isHaseMore = !this.isHaseMore;
      if (this.isHaseMore) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }

      this.$nextTick(() => {
        this.fetTableHeight();
      });
    },

    // 递归获取部门下所有id
    getDpIds(data) {
      let ids = this.deperIds || [];
      ids.push(data.departmentId);
      let arrs = data.children || [];
      for (var i = 0; i < arrs.length; i++) {
        ids.push(arrs[i].departmentId);
        let arr2 = arrs[i].children || [];
        for (var j = 0; j < arr2.length; j++) {
          this.getDpIds(arr2[j]);
        }
      }
      this.deperIds = ids;
      return this.deperIds;
    },

    queryBtn_Left() {
      this.leftTableData();
    },

    // 左边列表
    leftTableData() {
      this.$API
        .getGoodsGroupList({ groupName: this.groupNameKey })
        .then((res) => {
          if (res.data.status == "success") {
            let bmList = res.data.result || [];
            this.menuTableData = bmList;
            // this.menuTableData = this.diGuiGetMeun(bmList, "-1");
          }
        });
    },
    //递归获取数据
    diGuiGetMeun(data, parentId) {
      let tree = [];
      let temp;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.departmentName = item.departmentName;
          obj.children = [];
          temp = this.diGuiGetMeun(data, item.departmentId);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        for (var j = 0; j < tree.length - 1 - i; j++) {
          if (tree[j].sort > tree[j + 1].sort) {
            let temp = tree[j + 1];
            tree[j + 1] = tree[j];
            tree[j] = temp;
          }
        }
      }
      return tree;
    },

    //菜单新建
    menuadd(row) {
      console.log(row);

      this.getChongtu();
      if (row) {
        this.dialogFormData.parentId = row.typeId;
      } else {
      }
      this.$nextTick(() => {
        this.PopUpTitle = "新建品类类型";
        this.dialogType = "add";
        this.menushowBOX = true;
      });
    },
    //菜单修改
    menumodify(row) {
      console.log(row);
      this.dialogTypeR_fz = "edit";
      this.isShowDialog_fz = true;
      this.dialogTitle_fz = "修改分组";
      this.dialogForm_fz = JSON.parse(JSON.stringify(row));

      //   this.PopUpTitle = "修改品类类型";
      //   this.dialogType = "edit";
      //   this.menushowBOX = true;
      //   this.getChongtu();
      //   this.getConflictCategoryTypeList(row.typeId);

      //   this.dialogFormData.parentId = row.parentId + "";
      //   this.dialogFormData.typeId = row.typeId + "";
      //   this.dialogFormData.number = row.number + "";
      //   this.dialogFormData.typeName = row.typeName || "";
      //   this.dialogFormData.sort = row.sort || "1";
      //   this.dialogFormData.isShow = row.isShow + "" || "0";
      //   this.dialogFormData.childCategoryTypeList =
      //     row.childCategoryTypeList || [];
    },
    // 获取冲突品类
    getConflictCategoryTypeList(typeId) {
      this.$API
        .wtl_getConflictCategoryTypeList({ typeId: typeId })
        .then((res) => {
          console.log("res conflictTypeId", res);
          let datas = res.data.result || [];
          let ids = [];
          let conflictTypeIds = [];
          datas.forEach((item) => {
            ids.push(item.typeId);
            let obj = {
              conflictTypeId: item.typeId,
              operationType: "UPDATE",
            };
            conflictTypeIds.push(obj);
          });
          this.dialogFormData.conflictTypeId = ids;
          this.conflictTypeIds = conflictTypeIds;
        });
    },
    // 删除
    delBtns(id) {
      this.$confirm("是否删除该分组数据?", "提示:")
        .then(() => {
          let ids = [id];
          let param = {
            groupIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delGoodsGroup(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.leftTableData();
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    // 删除
    delMore() {
      if (this.selectTableData.length < 1) {
        this.$message.warning("请选择需要操作的数据");
        return false;
      }
      this.$confirm("是否删除所选数据?", "提示:")
        .then((respon) => {
          let ids = [];
          this.selectTableData.forEach((item) => {
            ids.push(item.typeId);
          });
          this.$API.wtl_delCategoryType({ typeIds: ids }).then((res) => {
            this.$message.success(res.data.message || "删除成功");
            this.leftTableData();
            this.getData();
          });
        })
        .catch((error) => {});
    },

    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia() {
      this.menushowBOX = false;
      this.dialogFormData = {
        conflictTypeId: [],
        isShow: "1",
        parentId: "",
        remark: "",
        sort: "1",
        typeName: "",
      };
    },
    // //  关闭弹窗按钮事件(关闭按钮)
    // diaCancelBtn() {

    // },
    //  弹窗确认保存事件
    diaConfirmBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == "add") {
            // 新建
            let param = {
              conflictTypeId: this.dialogFormData.conflictTypeId || [],
              isShow: this.dialogFormData.isShow || "0",
              parentId:
                this.dialogFormData.parentId == "-1"
                  ? ""
                  : this.dialogFormData.parentId,
              remark: this.dialogFormData.remark,
              sort: this.dialogFormData.sort || 1,
              typeName: this.dialogFormData.typeName,
            };
            this.$API.wtl_addCategoryType(param).then((res) => {
              this.menushowBOX = false;
              this.$message.success(res.data.message || "新建成功");
              this.getData();
              this.leftTableData();
            });
          } else {
            // 修改
            let conflictTypeIds = JSON.parse(
              JSON.stringify(this.conflictTypeIds)
            );

            // 原来冲突类型的id
            let array1 = conflictTypeIds.map((item) => {
              return item.conflictTypeId;
            });

            // 新增的冲突
            let array1ADD = [];

            //修改的冲突id
            let ids = this.dialogFormData.conflictTypeId;

            for (var a = 0; a < conflictTypeIds.length; a++) {
              if (ids.indexOf(conflictTypeIds[a].conflictTypeId) == -1) {
                conflictTypeIds[a].operationType = "DEL";
              }
            }
            for (var b = 0; b < ids.length; b++) {
              if (array1.indexOf(ids[b]) == -1) {
                let obj = {
                  conflictTypeId: ids[b],
                  operationType: "ADD",
                };
                array1ADD.push(obj);
              }
            }
            conflictTypeIds = conflictTypeIds.concat(array1ADD);

            let param = {
              isShow: this.dialogFormData.isShow || "0",
              parentId:
                this.dialogFormData.parentId == "-1"
                  ? ""
                  : this.dialogFormData.parentId,
              remark: this.dialogFormData.remark,
              sort: this.dialogFormData.sort || 1,
              typeName: this.dialogFormData.typeName,
              typeId: this.dialogFormData.typeId,
              conflictTypeIds: conflictTypeIds || [],
            };
            this.$API.wtl_udpateCategoryType(param).then((res) => {
              this.menushowBOX = false;
              this.$message.success(res.data.message || "修改成功");
              this.getData();
              this.leftTableData();
            });
          }
        }
      });
    },

    /***
     * ====== 弹窗组件方法 ======
     */

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    /***
     * ==============设置表格动态高度==============
     */
    fetTableHeight() {
      // 重置table高度
      this.tabelHeigth = 0;
      // 设置table高度
      let windonHeight =
        document.body.clientHeight || document.documentElement.clientHeight; //页面高度
      let queryHeigth = 40 + 10; //10==margin-top: 10  查询框高度
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表高度
      if (this.isHaseMore) {
        queryHeigth = this.$refs.queryForm.offsetHeight + 10; //查询框
      }
      let pagsHeigth = 40 + 10; //分页组件高度 20是上下margin
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航 10是
      let tabHeight = Math.floor(windonHeight - otherHeight) || ""; //

      this.$nextTick(() => {
        this.tableHeigth = tabHeight;
        this.leftHeight = windonHeight - 85 - 10;
        this.$refs.mytable.doLayout();
      });
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    rowClickLeft(tree) {
      console.log(tree, "tree");
      // this.queryData.categoryName = tree.categoryName;
      this.queryData.groupId = tree.groupId;
      this.getData();
    },

    // 获取数据
    getData() {
      let that = this;
      let param = {
        goodsName: this.queryData.goodsName || "",
        goodsStatus: this.queryData.goodsStatus || "",
        groupId: this.queryData.groupId || "",
        platformType: this.queryData.platformType || "",
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
      };
      this.$API.getGoodsList(param).then((res) => {
        that.tableData = res.data.result.data || [];
        that.pageCount = res.data.result.pageCount || 0;
        that.$nextTick(() => {
          that.fetTableHeight();
        });
      });
    },
    // 获取所属品类类型
    getChongtu() {
      let param = {
        pageStart: 1,
        pageTotal: 200,
      };
      this.$API.wtl_getCategoryTypeList(param).then((res) => {
        this.tableDataALL = res.data.result.data || [];
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        storageName: "",
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      this.$API
        .getGroupFindFieldSort({
          groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        })
        .then((res) => {
          let arr = res.data.result || [];
          if (arr.length > 0) {
            if (arr[0].sortObject) {
              that.myTableHeard = JSON.parse(arr[0].sortObject);
              this.sortName = arr[0].sortName;
            }
          } else {
            that.myTableHeard = that.tabelHeadeTitle;
          }
          let totalArr = [];
          //提取需要合计的字段
          for (var i = 0; i < that.myTableHeard.length; i++) {
            if (that.myTableHeard[i].isTotal) {
              totalArr.push(that.myTableHeard[i].field);
            }
          }
          that.totalArr = totalArr;
        });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = this.Tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      this.$API.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  width: 100%;
  display: flex;
}
.boxleft {
  // width: 40%;
  width: 380px;
  //   min-width: 390px;
  //   height: 88vh;
  overflow: auto;
  margin-right: 20px;
  // border: 1px solid #d7d7d7;
}
.boxright {
  // width: 70%;
  // flex: 1;
  // height: 500px;
  width: calc(100% - 430px);
}
.tablebut {
  margin-left: 15px;
}
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}

.glo_btnList {
  // align-items: center;

  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}

/deep/ .el-table .cell.el-tooltip {
  white-space: break-spaces;
  min-width: 50px;
  text-align: left;
}
</style>